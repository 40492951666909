<template>
<div class="container border-container">
    <el-row class="info-container" type="flex" align="middle" justify="space-between">
        <div class="info-tabs">
            <span>学生列表</span>
        </div>
    </el-row>
    <div class="table-container">
        <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
            <el-table-column prop="name" label="姓名" min-width="120" align="center"/>
            <!-- <el-table-column prop='class_name' label="所属班级" min-width="125" align="center"/> -->
            <el-table-column label="生日" min-width="125" align="center">
                <template slot-scope="scope">
                    {{scope.row.birthday | timeFilter(2)}}
                </template>
            </el-table-column>
            <el-table-column label="性别" min-width="125" align="center">
                <template slot-scope="scope">
                    {{scope.row.gender | genderFilter}}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="注册时间" min-width="155" align="center">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" align="center" v-if="auth('SCHOOL', 'ADMIN')">
                <template slot-scope="scope">
                    <el-button type="text" class="icon-btn" @click="handleDeleteStudent(scope.row.id)">
                        <el-tooltip placement="top" content="删除学生">
                            <i class="iconfont icon-delete"></i>
                        </el-tooltip>
                    </el-button>
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination class="right" layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
</div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: [String,Number]
        },
        schoolId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getTableData()
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },

        getTableData() {
            Core.Api.Student.list(
                this.currentPage,
                this.pageSize,
                this.orgId,
                this.schoolId,
                0,0,
            ).then(res => {
                console.log("getTableData -> res", res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },

        handleDeleteStudent(id) { // 删除 学生
            this.$confirm('确定要删除这名学生吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Student.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1)
                })
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container{
        @include flex(row, space-between, center);
        font-size: $font-size-md;
        font-weight: 500;
        color: $color-black;
        line-height: 22px;
        border-bottom: 1px solid rgba(230,234,238,1);
    }
}
</style>
